.bottom-nav-container {
    z-index: 40;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
}

.radix-themes.dark .bottom-nav-container {
    background-color: rgba(0, 0, 0, 0.6);
}
